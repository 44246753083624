<template lang="pug">
.code-insert-platform.mt-3.w-100
  om-body-text(bt400lg) {{ content || $t('codeInsertV2.shoprenter.description') }}
  om-accordion.mt-6
    om-accordion-item(
      :step="1"
      :trackPrefix="getSetupGuideTrackPrefix('step1')"
      :done="isConnected"
    )
      template(#name) {{ $t('codeInsertV2.shoprenter.steps.0.title') }}
      .code-insert-step-indent
        om-body-text(bt400md v-html="$t('codeInsertV2.shoprenter.steps.0.texts.0')")
        img.mt-3(
          :src="require('@/assets/admin/img/insert-code/platforms/shoprenter/step_1_1.jpg')"
        )
        om-body-text(bt400md v-html="$t('codeInsertV2.shoprenter.steps.0.texts.1')")
        img.mt-3(
          :src="require('@/assets/admin/img/insert-code/platforms/shoprenter/step_1_2.jpg')"
        )
        om-body-text(bt400md v-html="$t('codeInsertV2.shoprenter.steps.0.texts.2')")
        img.mt-3.mb-3(
          :src="require('@/assets/admin/img/insert-code/platforms/shoprenter/step_1_3.jpg')"
        )
</template>

<script>
  import childRouteMixin from '@/mixins/codeinsert/childRoute';
  import domainStatusMixin from '@/mixins/codeinsert/domainStatus';
  import platformMixin from '@/mixins/codeinsert/platform';

  export default {
    name: 'Shoprenter',
    mixins: [childRouteMixin, domainStatusMixin, platformMixin],
  };
</script>
